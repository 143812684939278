import {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Center} from '@chakra-ui/react';
import {Loader} from '@progress-fe/ui-kit';
import {useT} from '@progress-fe/core';

import {ProjectOut} from 'api';
import {TProjectsSortsModel} from 'stores/MyProjectsStore/models';
import {EProjectSortField, EProjectSortType} from 'core/enums';

import {IProjectListColumn, ListHead, ListRows} from './components';

interface IProps {
  isLoading: boolean;
  projects: ProjectOut[];
  createdBy: string; // FIXME: Should be a part of ProjectOut
  activeSort: TProjectsSortsModel;
  onUpdateActiveSort: (orderBy: EProjectSortType, orderByField: EProjectSortField) => void;
  onProjectRename: (project: ProjectOut, newName: string) => Promise<void>;
  onProjectRestore: (project: ProjectOut) => Promise<void>;
  onProjectDelete: (project: ProjectOut) => Promise<void>;
}

const ProjectListFC: FC<IProps> = ({
  isLoading,
  projects,
  createdBy,
  activeSort,
  onUpdateActiveSort,
  onProjectRestore,
  onProjectRename,
  onProjectDelete
}) => {
  const {t} = useT();

  const columns: IProjectListColumn[] = [
    {title: t('fields.name'), sortField: EProjectSortField.Name},
    {title: t('fields.category'), sortField: EProjectSortField.Type},
    {title: t('fields.updatedAt'), sortField: EProjectSortField.UpdatedAt},
    {title: t('fields.createdAt'), sortField: EProjectSortField.CreatedAt},
    {title: t('fields.activity')},
    {title: ''}
  ];

  const handleUpdateSort = (field?: EProjectSortField | null) => {
    if (!field) return;
    const isAscActive = activeSort.orderBy === EProjectSortType.Asc;
    const newSortType = isAscActive ? EProjectSortType.Desc : EProjectSortType.Asc;
    onUpdateActiveSort(newSortType, field);
  };

  const gridGap = '10px';
  const gridTemplate = '1fr 150px 160px 160px 160px 24px';

  return (
    <Box w={'100%'}>
      <ListHead
        columns={columns}
        gridTemplate={gridTemplate}
        gridGap={gridGap}
        activeSort={activeSort}
        onUpdateSort={handleUpdateSort}
      />

      {isLoading ? (
        <Center pt={'150px'}>
          <Loader />
        </Center>
      ) : (
        <>
          <ListRows
            isBin={projects.every((p) => p.deleted)}
            gridGap={gridGap}
            gridTemplate={gridTemplate}
            projects={projects}
            createdBy={createdBy}
            onRestore={onProjectRestore}
            onRename={onProjectRename}
            onDelete={onProjectDelete}
          />

          {projects.length === 0 && (
            <Center textAlign="center" h="64px" bg="white" fontSize="14px">
              {t('projects.notFound')}
            </Center>
          )}
        </>
      )}
    </Box>
  );
};

export const ProjectList = observer(ProjectListFC);
