import {FC, memo} from 'react';
import {Box, Text} from '@chakra-ui/react';
import {useT} from '@progress-fe/core';
import {Dialog} from '@progress-fe/ui-kit';

interface IProps {
  name: string;
  onDelete: () => Promise<void>;
  onClose: () => void;
}

const ProjectDeleteModalFC: FC<IProps> = ({name, onDelete, onClose}) => {
  const {t} = useT();

  return (
    <Dialog
      title={t('projects.deleteQuestion')}
      variant={'mediumWhite'}
      onClose={onClose}
      buttons={{
        apply: {title: t('actions.delete'), isDanger: true, onClick: async () => onDelete()},
        close: {title: t('actions.cancel')}
      }}
    >
      <Box w={'640px'} p={'0 56px'}>
        <Text fontSize={'14px'}>{t('projects.deleteSureMsg', {name})}</Text>
      </Box>
    </Dialog>
  );
};

export const ProjectDeleteModal = memo(ProjectDeleteModalFC);
