import {useCallback, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useParams} from 'react-router';
import {Grid, GridItem} from '@chakra-ui/react';
import {EStructureItem} from '@progress-fe/core';

import {useStore} from 'core/hooks';
import {LogViewer, ProjectLayout} from 'ui-kit';

import {ProjectForms, ProjectStructure, ProjectZones} from './components';

const TechProcessSceneFC = () => {
  const {techProcessStore, myProjectsStore} = useStore();
  const {uiState, logger} = techProcessStore;

  const [isExpanded, setIsExpanded] = useState(false);

  const {id, checkpointId} = useParams();

  useEffect(() => {
    if (!!id && !!checkpointId) {
      techProcessStore.initProject(id, checkpointId).then();
    }
    return () => {
      techProcessStore.uninitialize();
    };
  }, [id, checkpointId, techProcessStore]);

  const toggleIsExpanded = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  return (
    <ProjectLayout
      project={techProcessStore}
      isRunDisabled={techProcessStore.isSubWorkzone}
      onRunProject={techProcessStore.run}
      onRenameProject={myProjectsStore.renameProject}
      onDeleteProject={myProjectsStore.deleteProject}
      onRestoreProject={myProjectsStore.restoreProject}
    >
      <Grid
        position="relative"
        h="var(--height-layout)"
        gridTemplateColumns={isExpanded ? '1fr' : '280px 440px 1fr'}
      >
        {!isExpanded && (
          <>
            {/* PROJECT ITEMS: SETTINGS, ELEMENTS, MODELS ... */}
            <GridItem bg="bg" borderRight="1px" borderColor="border">
              <ProjectStructure />
            </GridItem>

            {/* SELECTED PROJECT ITEM */}
            <GridItem
              bg="bg"
              borderRight="1px"
              borderColor="border"
              overflowY={uiState.entityType === EStructureItem.Settings ? 'hidden' : 'auto'}
            >
              <ProjectForms />
            </GridItem>
          </>
        )}

        {/* SHOW STRUCTURE, CALCULATION OR RESULTS */}
        <GridItem position={'relative'}>
          <ProjectZones isExpanded={isExpanded} toggleIsExpanded={toggleIsExpanded} />
          {logger.isOpen && <LogViewer logPoints={logger.points} onClose={logger.toggleOpen} />}
        </GridItem>
      </Grid>
    </ProjectLayout>
  );
};

export const TechProcessScene = observer(TechProcessSceneFC);

export default TechProcessScene;
