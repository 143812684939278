import {FC, useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Flex, Grid, Text} from '@chakra-ui/react';
import {Svg} from '@progress-fe/ui-kit';

import {EProjectSortField, EProjectSortType} from 'core/enums';
import {TProjectsSortsModel} from 'stores/MyProjectsStore/models';

export interface IProjectListColumn {
  title: string;
  sortField?: EProjectSortField | null;
}

interface IProps {
  gridGap: string;
  gridTemplate: string;
  columns: IProjectListColumn[];
  activeSort: TProjectsSortsModel;
  onUpdateSort: (field?: EProjectSortField | null) => void;
}

const ListHeadFC: FC<IProps> = ({columns, gridTemplate, gridGap, activeSort, onUpdateSort}) => {
  const isActiveSortAsc = activeSort.orderBy === EProjectSortType.Asc;

  const isColumnHasSort = useCallback(
    (field?: EProjectSortField | null) => {
      return !!activeSort.orderByField && activeSort.orderByField === field;
    },
    [activeSort]
  );

  return (
    <Grid gap="12px" gridTemplateColumns={gridTemplate} p="0 24px">
      {columns.map((column) => {
        return (
          <Flex
            h="48px"
            gap={gridGap}
            align="center"
            key={column.title}
            _hover={{cursor: 'pointer'}}
            onClick={() => onUpdateSort(column.sortField)}
          >
            <Text fontWeight="600" fontSize="14px">
              {column.title}
            </Text>
            {isColumnHasSort(column.sortField) && (
              <Box transform={isActiveSortAsc ? 'rotate(0deg)' : 'rotate(180deg)'}>
                <Svg name={'ArrowDown'} />
              </Box>
            )}
          </Flex>
        );
      })}
    </Grid>
  );
};

export const ListHead = observer(ListHeadFC);
