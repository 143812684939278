import {FC, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Flex, Grid, Text} from '@chakra-ui/react';
import {ISvgMenuItem, SvgMenu} from '@progress-fe/ui-kit';
import {getTimeAgo, useT} from '@progress-fe/core';
import {useNavigate} from 'react-router';

import {ProjectOut} from 'api';
import {EActionType} from 'core/enums';
import {getProjectUrl} from 'core/utils';
import {ProjectTypeTag, ProjectRenameModal, ProjectDeleteModal} from 'ui-kit';

interface IProps {
  gridGap: string;
  gridTemplate: string;
  projects: ProjectOut[];
  createdBy: string; // FIXME: Should be a part of ProjectOut
  isBin: boolean;
  onRename: (project: ProjectOut, newName: string) => Promise<void>;
  onRestore: (project: ProjectOut) => Promise<void>;
  onDelete: (project: ProjectOut) => Promise<void>;
}

const ROW_HEIGHT = '64px';

const ListRowsFC: FC<IProps> = ({
  isBin,
  gridGap,
  gridTemplate,
  projects,
  createdBy,
  onRestore,
  onRename,
  onDelete
}) => {
  const [isRenameOpen, setIsRenameOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selected, setSelected] = useState<ProjectOut | null>(null);

  const navigate = useNavigate();
  const {t} = useT();

  const menuItems: ISvgMenuItem<EActionType>[] = [];
  menuItems.push({id: EActionType.Rename, name: t('actions.rename'), icon: 'Pencil'});
  if (isBin) menuItems.push({id: EActionType.Restore, name: t('actions.restore'), icon: 'Undo'});
  if (isBin) menuItems.push({id: EActionType.Delete, name: t('actions.delete'), icon: 'Bin'});
  if (!isBin) menuItems.push({id: EActionType.ToBin, name: t('actions.toBin'), icon: 'Bin'});

  const handleOnMenuAction = (project: ProjectOut, action: EActionType) => {
    switch (action) {
      case EActionType.Rename:
        setIsRenameOpen(true);
        setSelected(project);
        break;
      case EActionType.Restore:
        onRestore(project).then();
        break;
      case EActionType.ToBin:
        onDelete(project).then();
        break;
      case EActionType.Delete:
        setIsDeleteOpen(true);
        setSelected(project);
        break;
      default:
        break;
    }
  };

  return (
    <Box overflowY="auto" maxHeight="calc(var(--height-layout) - 220px)">
      {isRenameOpen && !!selected && (
        <ProjectRenameModal
          initialName={selected.name || ''}
          onChange={(v) => onRename(selected, v)}
          onClose={() => {
            setIsRenameOpen(false);
            setSelected(null);
          }}
        />
      )}

      {isDeleteOpen && !!selected && (
        <ProjectDeleteModal
          name={selected.name || ''}
          onDelete={() => onDelete(selected)}
          onClose={() => {
            setIsDeleteOpen(false);
            setSelected(null);
          }}
        />
      )}

      {projects.map((project) => {
        const {uuid, type, name, createdAt, updatedAt, checkpoints} = project;
        return (
          <Grid
            key={uuid}
            p="0 24px"
            bg="white"
            role="group"
            gap={gridGap}
            fontSize="14px"
            borderBottom="1px solid"
            borderColor="border"
            gridTemplateColumns={gridTemplate}
            onClick={() => navigate(getProjectUrl(type, uuid, checkpoints[0].uuid))}
            _last={{borderBottom: 'none'}}
            _hover={{cursor: 'pointer', bg: 'bg'}}
          >
            <Flex align="center" h={ROW_HEIGHT} overflow="hidden">
              <Text fontWeight="600" className="cut-text">
                {name}
              </Text>
            </Flex>
            <Flex align="center" h={ROW_HEIGHT}>
              <ProjectTypeTag type={type} />
            </Flex>
            <Flex align="center" h={ROW_HEIGHT}>
              <Text>{getTimeAgo(updatedAt.toISOString())}</Text>
            </Flex>
            <Flex align="center" h={ROW_HEIGHT}>
              <Text>{getTimeAgo(createdAt.toISOString())}</Text>
            </Flex>
            <Flex align="center" h={ROW_HEIGHT}>
              <Text>{createdBy}</Text>
            </Flex>
            <Flex align="center" h={ROW_HEIGHT}>
              <Box visibility="hidden" _groupHover={{visibility: 'visible'}}>
                <SvgMenu
                  items={menuItems}
                  menuIcon={'More'}
                  menuSize={'normal'}
                  variant={'mediumWhite'}
                  onSelect={(value) => {
                    handleOnMenuAction(project, value as EActionType);
                  }}
                />
              </Box>
            </Flex>
          </Grid>
        );
      })}
    </Box>
  );
};

export const ListRows = observer(ListRowsFC);
