import {flow, Instance, SnapshotIn, types} from 'mobx-state-tree';
import {ELogicalElement} from '@progress-fe/core';

import {EntityDetails} from 'core/models';
import {RJSFSchemas, TechProcessApi} from 'api';

const LogicalElementDetails = types
  .compose(
    EntityDetails,
    types.model('LogicalElementDetails', {
      logicalType: types.maybeNull(types.enumeration(Object.values(ELogicalElement))),
      deletable: types.maybe(types.boolean)
    })
  )
  .actions((self) => ({
    loadJsonSchemas: flow(function* (projectUuid: string, checkpointUuid: string) {
      if (!self.jsonSchemasRequest.isPending) {
        const response: {[key: string]: RJSFSchemas} = yield self.jsonSchemasRequest.send(
          TechProcessApi.techProcessGetLogicalElementSchemas.bind(TechProcessApi),
          {
            logicalElementUuid: self.uuid,
            projectUuid: projectUuid,
            checkpointUuid: checkpointUuid
          }
        );

        if (response) {
          self.setJsonSchemas(response);
        }
      }
    })
  }));

export type TLogicalElementDetailsModel = Instance<typeof LogicalElementDetails>;
export type TLogicalElementDetailsSnapshotIn = SnapshotIn<typeof LogicalElementDetails>;

export {LogicalElementDetails};
