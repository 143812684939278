import {FC, ReactElement} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Center} from '@chakra-ui/react';

import {ProjectOut} from 'api';
import {Header} from 'ui-kit/headers';
import {TUserModel} from 'core/models';
import {EComputeStatusCode} from 'core/enums';

import {
  PlayIconButton,
  SaveIconButton,
  UserIconButton,
  StatusIconButton,
  PlaySelectedIconButton
} from './components';

interface IProps {
  projectInfo: ProjectOut;
  currentUser: TUserModel;
  runStatus: EComputeStatusCode | null;
  isRunning: boolean;
  isRunDisabled?: boolean;
  lastSaveDate: Date | null;
  additionalActions?: ReactElement;
  onToggleLog: () => void;
  onRunCalculation: () => void;
  onRename: (newName: string) => Promise<void>;
  onRestore: () => Promise<void>;
  onDelete: () => Promise<void>;
  onSignOut: () => void;
  onSave: () => void;
}

const ProjectMainBarFC: FC<IProps> = ({
  projectInfo,
  currentUser,
  runStatus,
  isRunning,
  isRunDisabled,
  lastSaveDate,
  additionalActions,
  onRunCalculation,
  onToggleLog,
  onRename,
  onDelete,
  onRestore,
  onSignOut,
  onSave
}) => {
  return (
    <Header project={projectInfo} onRename={onRename} onRestore={onRestore} onDelete={onDelete}>
      <Center gap="8px">
        {!!additionalActions && (
          <>
            {additionalActions}
            <Box w="1px" bg="border" h="32px" />
          </>
        )}
        <PlayIconButton isDisabled={isRunning || isRunDisabled} onRun={onRunCalculation} />
        <PlaySelectedIconButton isDisabled={isRunning || isRunDisabled} />
        <StatusIconButton runStatus={runStatus} isDisabled={isRunning} onClick={onToggleLog} />

        <Box w="1px" bg="border" h="32px" />
        <SaveIconButton isDisabled={isRunning} lastSaveDate={lastSaveDate} onSave={onSave} />
        <UserIconButton currentUser={currentUser} onSignOut={onSignOut} />
      </Center>
    </Header>
  );
};

export const ProjectMainBar = observer(ProjectMainBarFC);
