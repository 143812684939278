import {FC, memo} from 'react';
import {Box, Flex} from '@chakra-ui/react';
import {RFSvg} from '@progress-fe/rf-core';
import {ISvgMenuItem, Svg, SvgMenu} from '@progress-fe/ui-kit';

import {EActionType} from 'core/enums';

import {IStructureItem} from '../../StructureItem';

interface IProps {
  item: IStructureItem;
  isChild?: boolean;
  isChildShown?: boolean;
  hasChildItems?: boolean;
  menuItems?: ISvgMenuItem<EActionType>[];
  onMenuAction?: (value: EActionType) => void;
  onToggleChildren?: () => void;
  onClick?: (uuidOrSubUuid: string) => void;
}

const ItemOrChildFC: FC<IProps> = ({
  item,
  isChild,
  menuItems,
  isChildShown,
  hasChildItems,
  onToggleChildren,
  onMenuAction,
  onClick
}) => {
  return (
    <Flex
      gap="5px"
      role="group"
      justify="space-between"
      p={`5px 8px 5px ${isChild ? '38px' : '8px'}`}
      _hover={{bg: item.isActive ? 'accent' : 'accentMid', cursor: 'pointer'}}
      {...(item.isActive ? {bg: 'accent', color: 'white'} : {color: 'black'})}
      onClick={() => onClick?.(item.uuid)}
    >
      <Flex gap="5px">
        {!!hasChildItems && (
          <Flex sx={{transform: isChildShown ? 'rotate(270deg)' : 'rotate(180deg)'}}>
            <Svg size={'s12'} name={'Back'} onClick={onToggleChildren} />
          </Flex>
        )}
        {!!item.icon && <Svg name={item.icon} />}
        {!!item.elementIcon && <RFSvg name={item.elementIcon} />}
        <Box maxW={!!item.suffix && !isChild ? '100px' : '220px'} className="cut-text">
          {item.name}
        </Box>
      </Flex>
      <Flex gap="5px">
        {!!item.suffix && !isChild && (
          <Box textAlign="right" color={item.isActive ? 'accentMid' : 'accent'}>
            {item.suffix}
          </Box>
        )}
        {!!menuItems && (
          <Box visibility="hidden" _groupHover={{visibility: 'visible'}}>
            <SvgMenu
              menuSize={'small'}
              menuIcon={'More'}
              items={menuItems}
              variant={'compactGray'}
              onSelect={(value) => onMenuAction?.(value as EActionType)}
            />
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

export const ItemOrChild = memo(ItemOrChildFC);
