import {FC, memo, useState} from 'react';
import {Box} from '@chakra-ui/react';
import {Dialog, Input} from '@progress-fe/ui-kit';
import {useT} from '@progress-fe/core';

interface IProps {
  initialName: string;
  onChange: (newName: string) => Promise<void>;
  onClose: () => void;
}

const ProjectRenameModalFC: FC<IProps> = ({initialName, onChange, onClose}) => {
  const [name, setName] = useState(initialName);

  const {t} = useT();

  return (
    <Dialog
      title={t('projects.rename')}
      variant={'mediumWhite'}
      onClose={onClose}
      buttons={{
        apply: {title: t('actions.rename'), onClick: () => onChange(name)},
        close: {title: t('actions.cancel')}
      }}
    >
      <Box w={'640px'} p={'0 56px'}>
        <Input
          size="md"
          value={name}
          variant="outline"
          placeholder={t('fields.name')}
          onChange={setName}
        />
      </Box>
    </Dialog>
  );
};

export const ProjectRenameModal = memo(ProjectRenameModalFC);
